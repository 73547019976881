import * as React from 'react'
import { point, featureCollection } from '@turf/helpers'
import nearestPoint from '@turf/nearest-point'
import {
  LazyLocationFeature,
  useLazyLocationFeatures,
} from './useLazyLocationFeatures'
import type { LazyLocation } from '../types'

interface ContextActions {
  request: () => void
}
type ContextState =
  | { state: 'idle' }
  | { state: 'resolving' }
  | {
      state: 'resolved'
      coords: GeolocationCoordinates
      nearestLocation: LazyLocation
    }
  | { state: 'persmission-denied' }
  | { state: 'error'; error: string }

type ContextValue = ContextActions & ContextState

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const Context = React.createContext<ContextValue>(null!)

interface ProvdierProps {
  children: React.ReactNode
}

export const UserLocationProvider = (props: ProvdierProps) => {
  const [state, setState] = React.useState<ContextState>({ state: 'idle' })
  const lazyLocationFeatures = useLazyLocationFeatures()

  const request = async () => {
    if (state.state === 'resolved') {
      return
    }

    if (!navigator || !navigator.geolocation) {
      return setState({
        state: 'error',
        error: 'Geolocation is not supported.',
      })
    }

    setState({ state: 'resolving' })

    const onError: PositionErrorCallback = (error) => {
      switch (error.code) {
        case error.POSITION_UNAVAILABLE:
          return setState({ state: 'persmission-denied' })

        default:
          return setState({ state: 'error', error: error.message })
      }
    }

    const onSuccess: PositionCallback = async ({ coords }) => {
      const userPoint = point([coords.longitude, coords.latitude])
      const locationFeatures = featureCollection(lazyLocationFeatures)
      const nearestLocation = (nearestPoint(
        userPoint,
        locationFeatures,
      ) as unknown) as LazyLocationFeature

      const res = await fetch(nearestLocation.properties.lazyLocationURL)
      if (!res.ok) {
        return setState({ state: 'error', error: res.statusText })
      }

      const data = await res.json().catch(() =>
        setState({
          state: 'error',
          error: 'Failed to parse location payload data',
        }),
      )
      setState({ state: 'resolved', coords, nearestLocation: data })
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError, {
      enableHighAccuracy: false,
      timeout: Infinity,
      maximumAge: 0,
    })
  }

  const value: ContextValue = {
    request,
    ...state,
  }

  return <Context.Provider value={value}>{props.children}</Context.Provider>
}

export function useUserLocation(autoLoad = false): ContextValue {
  const value = React.useContext(Context)

  React.useEffect(() => {
    if (autoLoad) {
      value.request()
    }
  }, [autoLoad])

  return value
}
